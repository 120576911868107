/**
 * @file
 * Styles for the front page.
*/

@import "variables";

// Temp Fix for Flagline front page display

#header {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
  .front #content {
    display: none;
  }
  .front #content_above {
    display: inline-block !important;
    float: left;
  }
}

// ^END

.front #main {
  max-width: none;
  padding: 0;
  z-index: 1;
  .pagewidth {
    max-width: 1400px;
    background: none;
    padding: 0 90px;
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
}

//
// Slideshow
//

.front #content_above {
  background: $blue;
  display: inline-block;
  width: 100%;
  margin-left: 0;
  top: 0;
  position: relative;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

#block-views-front-page-slideshow-block {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding: 0 90px;
  @media only screen and (max-width: 767px) {
    padding: 0;
  }

  // Slideshow Controls ( 1,2,3 or o o o)
  ol.flex-control-nav {
    width: initial;
    text-align: initial;
    position: absolute;
    bottom: -40px;
    top: 10px;
    right: 10px;
  }

  #flexslider-1 { // Front Page Slideshow
    max-width: 1220px;
    @include zen-grid-container();
    width: 100%;
    margin: 0 auto;
    overflow: visible;
    ul.slides > li { // slide
      position: relative;
      overflow: hidden;
      background: $orange;
      .views-field-nothing { // Body
        @include zen-grid-item(10,1);
        @media only screen and (max-width: 1100px) {
          @include zen-grid-item(24,1);
          position: absolute;
          background: $orange;
          background: rgba(223,174,0,.8);
          bottom: 0;
        }
        @media only screen and (max-width: 767px) {
          height: intrinsic;
          display: none;
        }
        .slide-title {
          @media only screen and (max-width: 1300px) {
            //font-size: $fs-l;
          }
          @media only screen and (max-width: 1100px) {
            font-size: $fs-m;
            text-align: center;
          }
          @media only screen and (max-width: 767px) {
            display: none;
          }
          h2 {
            margin-top: 20px;
            line-height: 0.8em;
            @media only screen and (max-width: 1100px) {
              margin-top: 5px;
            }
          }
          a {
            color: $red-dark;
            &:hover {
              text-decoration: none;
              color: lighten($red-dark, 15%);
            }
          }
          &:not(.slide-title-size-default) {
            h2 a {
              white-space: normal;
            }
          }
        }

        .slide-body {
          @media only screen and (max-width: 1100px) {
            display: none;
          }
        }

        .slide-title-size-small {
          font-size: 100%;
        }
        .slide-title-size-medium {
          font-size: 120%;
        }
        .slide-title-size-large {
          font-size: 140%;
        }
        .slide-title-size-xlarge {
          font-size: 160%;
        }

        .slide-text-size-small p:first-child,  {
          font-size: 90%;
        }
        .slide-text-size-default p:first-child {
          font-size: 100%;
        }
        .slide-text-size-large p:first-child {
          font-size: 110%;
        }
        .slide-text-size-xlarge p:first-child {
          font-size: 130%;
        }
      }
      .views-field-body {
        clear: left;
      }
      .views-field-field-slideshow-image { // Slide Image
        @include zen-grid-item(14,11);
        //height: 375px;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        @media only screen and (max-width: 1100px) {
          @include zen-grid-item(24,1);
          padding-left: 0;
          padding-right: 0;
        }
        @media only screen and (max-width: 767px) {
          @include zen-grid-item(24,1);
          height: intrinsic;
          background: $red;
          padding-right: 0;
          padding-left: 0;
        }
        a {
          line-height: none;
        }
        img {
          margin-bottom: 0;
          max-width: none;
          height: auto;
          width: 100%;
          @media only screen and (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
  }

  // Flexslide Reset
  .flexslider {
    border: none;
    @include border-radius(0);
  }

  // SS

  #flexslider-1 {
    margin-bottom: 0;
  }
  #flexslider-1 {
    @media only screen and (max-width: 767px) {
      margin-top: -6px;
    }
    .flex-direction-nav {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .flex-prev, .flex-next {
      background: none;
      width: 48px;
      line-height: 1em;
      height: 75px;
      color: transparent;
      font-size: 74px;
      text-indent: inherit;
      position: absolute;
      overflow: hidden;
      display: block;
      opacity: 0.8;
      text-indent: -9999px;
      @media only screen and (max-width: 767px) {
        top: 50%;
      }
      &:after {
        //content: "<";
        font-family: fontello;
        color: white;
        color: rgba(white, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        text-indent: 0;
        @include transition(
          color 0.2s ease-in-out
        );
      }
      &:hover:after {
        color: white;
        color: rgba(white, 0.3);
      }
    }
    .flex-prev { // Previous
      left: -70px;
      top: 50%;
      margin-top: -35px;
      &:after {
        content: "<";
      }
      &:hover {
        &:after {

        }
      }
    }
    .flex-next { // Next
      right: -70px;
      top: 50%;
      margin-top: -35px;
      &:after {
        content: ">";
      }
      &:hover {
      }
    }
  }
}

// Slideshow Animation
#block-views-front-page-slideshow-block {

  // Slide
  ul.slides > li {
    // Initially Hide Slides (Flexslider JS will turn them on)
    display: none;

    // Text Fields
    .bt-container {
      position: relative;
      left: -700px;
      @include transition(
        left 0.5s ease-in-out 0.4s
      );
    }
    // Body
    .slide-body {
      position: relative;
      left: -700px;
      @include transition(
        left 0.5s ease-in-out 0.6s
      );
    }

    &.flex-active-slide {
      // Text Fields
      .bt-container, .slide-body {
        left: 0;
      }
    }

  }

}

//
// Front Page Promo Banner
//

#block-views-promo-banner-block {
  width: 100%;
  max-width: 1400px;
  padding: 0 90px;
  @media only screen and (max-width: 767px) {
    padding: 10px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 600px) {
    margin: 0 auto;
    height: initial;
  }
  margin: 0 auto 20px;
  //background: $orange;
  position: relative;
  height: 100px;
  .view-promo-banner {
    background: $orange;
    display: inline-block;
    width: 100%;
    height: 100px;
    @media only screen and (max-width: 600px) {
      height: initial;
    }
  }
  a {
    color: white;
    text-decoration: none;
  }
  img {
    margin-bottom: 0;
  }
  .views-field-title {
    float: left;
    padding: 10px 10px 0;
    margin-right: 380px;
    @media only screen and (max-width: 600px) {
      padding: 5px;
      width: 100%;
      text-align: center;
    }
    h2 {
      font-size: 2.5em;
      line-height: 1em;
      font-weight: normal;
      margin: 0;
      @media only screen and (max-width: 959px) {
        font-size: 2em;
      }
      a {
        display: block;
      }
    }
  }
  .views-field-body {
    float: left;
    padding: 0 10px 10px;
    clear: left;
    color: white;
    margin-right: 380px;
    @media only screen and (max-width: 650px) {
      display: none;
    }
  }
  .views-field-field-image {
    width: 380px;
    position: absolute;
    right: 90px;
    //top: 1px;
    @media only screen and (max-width: 767px) {
      right: 10px;
      top: 10px;
    }
    @media only screen and (max-width: 600px) {
      right: 0;
      text-align: center;
      width: 100%;
      position: relative;
      top: 0;
    }
  }
}
